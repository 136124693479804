<template>
  <v-popup v-model:visible="isOpen" overlay closeOverlay keyPopup="popupEbook" :closeable="false">
    <template #header class="mb-7.5 relative">
            <span
                class="i-custom-close2 w-3 h-3 text-bw-24 absolute z-5 top-3 right-3 lg:top-4.5 lg:right-4.5 cursor-pointer"
                @click="closeOTP()"
            ></span>
      <div class="">
        <h5 class="text-3xl leading-44px lg:text-32px font-[550] leading-10 text-center mb-3 text-center gap-1">
          {{ $t('DOWNLOAD') }}
          <span class="text-primary"> E-book </span>
        </h5>
      </div>
    </template>
    <form @submit.prevent="onSubmit" id="form-ebook" class="mt-4">
      <div class="grid grid-cols-1 gap-y-3 lg:gap-y-6 lg:min-w-84">
        <div class="w-full">
          <label
              for="name_ebook"
              :class="errorName ? '!text-red-500' : ''"
              class="uppercase text-black/25 text-13px font-[550] block pb-2 uppercase"
          >{{ $t('FIELD_NAME') }}*</label
          >
          <input
              id="name_ebook"
              name="name"
              type="text"
              :class="errorName ? '!placeholder:text-red-500/80 border-red-500 !text-red-500' : ''"
              class="border border-#CBCBCB font-medium outline-none w-full rounded-28px py-15px text-15px px-5.5 text-black/80 placeholder-bw-12/50 capitalize h-13.5 flex items-center"
              :placeholder="$t('INPUT_NAME')"
              v-model="name"
              @blur="checkName()"
          />
          <Transition name="fade">
                        <span class="text-red-500 pl-5 mt-2" v-if="errorName">
                            {{ $t('PLEASE_ENTER_YOUR_NAME') }}
                        </span>
          </Transition>
        </div>
        <div class="w-full">
          <label for="phone_ebook" class="uppercase text-black/25 text-13px font-[550] block pb-2 uppercase"
          >{{ $t('GENDER') }}*</label
          >
          <global-select
              :data="dataGender"
              v-model:senData="gender"
              class="rounded-full h-13.5 flex items-center"
          ></global-select>
        </div>
        <div class="relative z-1">
          <label
              for="email_ebook"
              :class="errorEmail ? '!text-red-500' : ''"
              class="text-black/25 text-13px font-[550] block pb-2 uppercase"
          >
            Email*
          </label>
          <input
              type="email"
              id="email_ebook"
              class="border border-#CBCBCB font-medium outline-none w-full rounded-28px py-15px text-15px px-5.5 text-black/80 placeholder-bw-12/50 h-13.5 flex items-center"
              :class="errorEmail ? '!placeholder:text-red-500/80 border-red-500 !text-red-500' : ''"
              :placeholder="t('ENTER_YOUR_EMAIL')"
              v-model="email"
              @blur="checkEmail()"
          />
          <Transition name="fade">
                        <span class="text-red-500 pl-5 mt-2" v-if="errorEmail">
                            {{ $t('PLEASE_ENTER_A_VALID_EMAIL') }}
                        </span>
          </Transition>
        </div>

        <GlobalVerifyForm :error="errorChecked" v-model:checked="isChecked" class="flex justify-center"/>
      </div>
    </form>
    <template #footer>
      <button
          class="mt-5 relative w-full btn-submit-contact bg-accent-01 lg:bg-primary text-white font-semibold uppercase text-sm max-h-14 rounded-30px py-4.5 flex items-center justify-center h-13.5 cursor-pointer"
          :class="successSubmit ? '' : 'pointer-events-none'"
          @click="onSubmit"
      >
        <p v-if="successSubmit">{{ $t('DOWNLOAD_E_BOOK') }}</p>
        <div v-else class="absolute loaderForm"></div>
      </button>
    </template>
  </v-popup>
  <popup-result-success v-model:visible="isSuccess"></popup-result-success>
  <popup-result-fail v-model:visible="isFail" :title="notificationError" :intro="introError"></popup-result-fail>
</template>

<script setup lang="ts">
import {useReCaptcha} from 'vue-recaptcha-v3'
import PopupResultSuccess from './__PopupResultSuccess.vue'
import PopupResultFail from './__PopupResultFail.vue'
import {
  DOWNLOAD_E_BOOK_EVENT_ID,
  DOWNLOAD_E_BOOK_KEY,
  useEventTracking
} from "../../../composables/ackee/event";

const {t} = useI18n()
const emits = defineEmits(['closeOTP'])
const closeOTP = () => {
  isOpen.value = false
}
const dataGender = [
  {
    id: 'M',
    content: '<p>' + t('MR') + '</p>'
  },
  {
    id: 'S',
    content: '<p>' + t('MS') + '</p>'
  }
]

const langCode = useRoute().params.langCode
const email = ref()
const name = ref()
const gender = ref()
const errorEmail = ref(false)
const errorName = ref(false)
const successSubmit = ref(true)
const notification = ref()
const errorChecked = ref(false)
const isChecked = ref()
const isOpen = ref(true)
const notificationError = ref()
const introError = ref()
const isSuccess = ref(false)
const isFail = ref(false)

const checkEmail = () => {
  let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
  if (email.value) {
    if (filter.test(email.value)) {
      errorEmail.value = false
    } else {
      errorEmail.value = true
    }
  } else {
    errorEmail.value = true
  }
}

const checkName = () => {
  if (name.value) {
    errorName.value = false
  } else {
    errorName.value = true
  }
}
const checkChecked = () => {
  if (isChecked.value) {
    errorChecked.value = false
  } else {
    errorChecked.value = true
  }
}

watch(
    () => isChecked.value,
    () => {
      checkChecked()
    }
)

watch(
    () => isOpen.value,
    () => {
      if (!isFail.value && !isSuccess.value) {
        emits('closeOTP')
      }
    }
)

watch(
    () => isFail.value,
    (newValue) => {
      if (!newValue) {
        emits('closeOTP')
      }
    }
)

watch(
    () => isSuccess.value,
    (newValue) => {
      if (!newValue) {
        emits('closeOTP')
      }
    }
)

const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
  // optional you can await for the reCaptcha load
  await recaptchaInstance?.recaptchaLoaded()

  // get the token, a custom action could be added as argument to the method
  const token = await recaptchaInstance?.executeRecaptcha('login')

  return token
}
const onSubmit = async (): Promise<void> => {
  checkChecked()
  checkName()
  checkEmail()
  if (!errorChecked.value && !errorName.value && !errorEmail.value) {
    useEventTracking(DOWNLOAD_E_BOOK_EVENT_ID, DOWNLOAD_E_BOOK_KEY)

    successSubmit.value = false
    const token = await recaptcha()
    const infoForm = ref({
      lang: langCode,
      name: capitalizeFirstLetterOfWords(name.value),
      gender: gender.value,
      email: email.value
    })
    fetch(`/api/ebook?response=${token}`, {
      method: 'POST',
      body: JSON.stringify(infoForm.value)
    })
        .then((response) => response.json())
        .then((data) => {
          successSubmit.value = true
          if (data.code == 201) {
            isOpen.value = false
            isSuccess.value = true
            name.value = ''
            email.value = ''
          } else {
            isOpen.value = false
            isFail.value = true
            notificationError.value = t('THE_SERVER_IS_HAVING_PROBLEMS')
            introError.value = t('SORRY_WE_ARE_EXPERIENCING_SOME_TECHNICAL2')
          }
        })
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  display: none;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
