<template>
  <section class="relative h-50 lg:h-81 aspect-3/2 md:aspect-16/5 lg:aspect-16/9 w-full overflow-hidden">
    <div
        class="absolute inset-0 -z-1 min-w-100vw"
        style="
                background: radial-gradient(
                    247.42% 100.06% at 32.37% 100%,
                    #ffa17d 5.03%,
                    #ff8050 42.75%,
                    #ed5a22 100%
                );
            "
    >
      <img
          src="/images/circle/hinh12.svg"
          loading="lazy"
          alt="circle"
          class="w-100 lg:w-180 aspect-1/1 absolute -top-10 left-0 lg:-top-1/2 lg:-left-50 z-10"
      />
      <img
          src="/images/circle/hinh13.svg"
          alt="circle"
          class="w-100 lg:w-180 aspect-1/1 absolute top-0 lg:-top-40 -right-60 lg:-right-100 z-10 md:block hidden"
      />
    </div>

    <div class="relative z-10 container h-full flex flex-col justify-end">
      <global-breadcrumb :breadcrumb="breadcrumb" class="font-medium" isLight></global-breadcrumb>
      <h1 class="text-3xl md:text-56px font-[550] text-white leading-10 lg:leading-17 pb-10 xl:pb-22">
        {{ titleRender() }}
      </h1>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String
  },
  breadcrumb: {
    type: Array as any
  }
})
const titleRender = () => {
  if (props.title) {
    return props.title
  } else {
    return props.breadcrumb[props.breadcrumb.length - 1]?.title
  }
}
</script>

<style></style>
