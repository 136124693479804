<template>
  <section class="container mb-20 overflow-hidden">
    <div
        class="rounded-5 p-10 lg:p-15 lg:pl-28.5 flex lg:flex-row flex-col justify-between relative items-center"
        style="
                background: radial-gradient(
                    247.42% 100.06% at 32.37% 100%,
                    #ffa17d 5.03%,
                    #ff8050 42.75%,
                    #ed5a22 100%
                );
            "
    >
      <div class="absolute top-0 bottom-0 left-40% aspect-1/1">
        <img src="/images/circle/hinh9.svg" alt="circle" class="w-full aspect-1/1 object-fit"/>
      </div>
      <div class="w-full lg:w-112 flex flex-col gap-8 lg:mb-0 mb-10">
        <v-text
            data-aos="fade-right"
            data-aos-once="true"
            tag="h3"
            class="text-2xl leading-3xl lg:text-30px lg:leading-44px text-white font-[550]"
            default_value=" Khám phá xu hướng chọn và mua bảo hiểm 2023"
            option_key="newsIndexPage.textDownload.global"
        />
        <button-primary
            data-aos="fade-up"
            data-aos-once="true"
            :title="t('DOWNLOAD_E_BOOK')"
            @click="isPopup = !isPopup"
            option
        ></button-primary>
      </div>
      <div data-aos="zoom-in" data-aos-once="true" class="w-full lg:w-125 lg:aspect-125/80">
        <v-image
            option_key="newsIndexPage.imageDownload.global"
            class="w-full h-full object-cover rounded-15px"
            :width="900"
        >
        </v-image>
      </div>
    </div>
  </section>
  <popup v-if="isPopup" @closeOTP="isPopup = false"></popup>
</template>

<script setup lang="ts">
import Popup from './__PopupEbook.vue'

const {t} = useI18n()

const isPopup = ref(false)
</script>

<style></style>
