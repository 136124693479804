<template>
  <nav class="bg-white">
    <ul
        class="gap-10 overflow-x-scroll custom-scrollbar-global w-full flex scroll py-7.5 mb-5 border-b border-bw-08 !lg:hidden px-5"
    >
      <li class="group min-w-max" v-for="(category, index) in data" :key="index" v-if="data">
        <nuxt-link
            :to="category?.url"
            class="text-bw-15 group-hover:text-primary duration-300 font-semibold block"
            :class="idActive && idActive == category?.slug ? '!text-primary' : ''"
        >
          {{ category?.title }}
        </nuxt-link>
      </li>
    </ul>
    <div class="container">
      <div
          class="md:border-bw-08 lg:border-b py-4 flex lg:flex-row flex-col gap-5 lg:gap-10 justify-between lg:items-center w-full mb-10 lg:mb-20"
      >
        <!--  NAVIGATION -->
        <div
            data-aos="fade-right"
            data-aos-once="true"
            v-if="isSwiper"
            class="pb-5 md:pb-0 w-full max-w-full lg:max-w-6/10 relative gap-10 items-center lg:flex hidden"
        >
          <button
              class="prev-swiper-news lg:text-primary lg:bg-final-03 hover:lg:bg-primary hover:lg:text-white border text-white w-9 h-9 flex justify-center items-center rounded-full duration-300"
              v-if="data.length > 5"
          >
            <span class="w-5 h-5 i-custom-chevron-right rotate-180"></span>
          </button>
          <Swiper
              :modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation]"
              :navigation="{
                            nextEl: '.next-swiper-news',
                            prevEl: '.prev-swiper-news'
                        }"
              :breakpoints="{
                            1024: { slidesPerView: 5 },
                            624: { slidesPerView: 4 },
                            320: { slidesPerView: 3 }
                        }"
              :space-between="40"
              :grabCursor="true"
              :freeMode="true"
              class="flex-1"
          >
            <SwiperSlide
                class="group min-w-max !w-auto"
                v-for="(category, index) in data"
                :key="index"
                v-if="data"
            >
              <nuxt-link
                  :to="category?.url"
                  class="text-bw-15 group-hover:text-primary duration-300 font-semibold block"
                  :class="idActive && idActive == category?.slug ? '!text-primary' : ''"
              >
                {{ category?.title }}
              </nuxt-link>
            </SwiperSlide>
          </Swiper>
          <button
              class="next-swiper-news lg:text-primary lg:bg-final-03 hover:lg:bg-primary hover:lg:text-white border lg:border-none text-white w-9 h-9 flex justify-center items-center rounded-full duration-300"
              v-if="data.length > 5"
          >
            <span class="w-5 h-5 i-custom-chevron-right"></span>
          </button>
        </div>

        <!-- SEARCH -->
        <form
            data-aos="fade-left"
            data-aos-once="true"
            @submit.prevent="submitSearch()"
            class="flex gap-2 px-4 py-3 items-center bg-white rounded-2.5 w-full lg:w-75 input-search-faq border border-bw-08"
        >
          <span class="i-ic:twotone-search w-6 h-6 text-primary"></span>
          <input
              type="text"
              :placeholder="t('FIND_THE_RIGHT_QUESTION')"
              class="flex-1 border-none text-bw-16 placeholder-text-bw-16 text-13px leading-18px text-black"
              v-model="search"
          />
        </form>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
const {t} = useI18n()

defineProps({
  data: {
    type: Array as any
  },
  idActive: {
    type: String as any
  }
})
// const res = ref()
const submitSearch = async () => {
  let url = ref()
  if (useRoute().params.langCode == 'vi-VI') {
    url.value = '/tim-kiem'
  } else {
    url.value = '/en/search'
  }
  useRouter().push({
    path: url.value,
    query: {
      type: t('URL_NEWS'),
      word: search.value,
      page: 1
    }
  })
}

const search = ref()
const isSwiper = ref(false)
onMounted(() => {
  isSwiper.value = true
})
</script>

<style>
ul.scroll {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

ul.scroll li {
  scroll-snap-align: center;
}

.prev-swiper-news.swiper-button-disabled,
.next-swiper-news.swiper-button-disabled {
  @apply pointer-events-none opacity-60;
}
</style>
